<template>
  <b-container class="gifts-view">
    <PageHeader
      hideBackButton
      class="mb-4"
    >
      Подарки
    </PageHeader>

    <BonusAndGiftSwitcher/>

    <PageLoading :isLoading="isLoading"/>

    <template v-if="!isLoading">
      <div class="gifts-view-error" v-if="giftList.length === 0">
        <div class="gifts-view-error-content">
          <h3>Здесь пока ничего нет...</h3>
          <p>Участвуйте в наших акциях и получайте ценные подарки!</p>
          <router-link to="/promotions">К акциям <b-icon icon="arrow-right"/></router-link>
        </div>
        <div class="gifts-view-error-image">
          <noGifts/>
        </div>

      </div>
      <b-row v-else>
        <b-col
          v-for="gift in giftList"
          :key="gift.uuid"
          cols="12"
          md="6"
          xl="4"
          class="mb-4 mb-xl-5"
        >
          <GiftItem
            :img="gift.giftTypePhoto !== '' ? gift.giftTypePhoto : undefined"
            :file="gift.gift"
            :type="gift.giftType"
            :activatedAt="gift.activatedAt"
          />
        </b-col>
      </b-row>
    </template>
  </b-container>
</template>

<script>
import PageHeader from '../components/PageHeader.vue';
import PageLoading from '../components/PageLoading.vue';
import BonusAndGiftSwitcher from '../components/BonusAndGiftSwitcher.vue';
import GiftItem from '../components/GiftItem.vue';
import { getGifts } from '../api/profi_club/gifts';
import noGifts from '../assets/noGifts.svg';

export default {
  name: 'Gifts',
  components: {
    GiftItem,
    PageHeader,
    PageLoading,
    BonusAndGiftSwitcher,
    noGifts,
  },
  data: () => ({
    isLoading: true,
    giftList: [],
    errorMessage: 'Ошибка получения подарков',
  }),
  async mounted() {
    try {
      const response = await getGifts();
      if (response.status === 200) this.giftList = response.data.list;
      this.isLoading = false;
    } catch (e) {
      if (e.response.status === 404) {
        this.errorMessage = e.response.data;
        this.isLoading = false;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.gifts-view {
  padding: 25px 10px 50px 10px;
  &-error {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-image {
      svg {
        width: 100%;
        height: 489px;
      }
    }
  }
}
@media (max-width: 1200px) {
  .gifts-view {
    &-error {
      flex-direction: column-reverse;
      &-content {
        margin-top: 20px;
      }
    }
  }
}
@media (max-width: 576px) {
  .gifts-view {
    &-error {
      align-items: flex-start;
      &-image {
        width: 100%;
        svg {
          width: 100%;
          height: 300px;
        }
      }
    }
  }
}
</style>
