<template>
  <div class="gift-item">
    <div
      v-if="img !== ''"
      class="gift-item__image"
      :style="{ 'background-image': `url(${img})` }"
    />
    <div
      v-else
      class="gift-item__image gift-item__image--default"
    />

    <b-row
      class="mt-3"
      align-v="center"
      align-h="between"
    >
      <b-col cols="auto">
        <time :datetime="activatedAt | getDate">
        <i>
          <small>
            Получен: {{ activatedAt | getDate }}
          </small>
        </i>
        </time>
      </b-col>
      <b-col cols="auto">
        <b-button
          :href="file"
          target="_blank"
          variant="outline-primary"
          size="sm"
        >
          <b-icon
            icon="download"
            aria-hidden="true"
          />
          Скачать
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'GiftItem',
  props: {
    file: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    activatedAt: {
      type: Number,
      required: true,
    },
    img: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>

<style lang="scss">
.gift-item {
  &__image {
    width: 100%;
    border-radius: 15px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &::before {
      content: '';
      padding-top: 56%;
      display: block;
    }

    &--default {
      background-image: url('/img/default-image.svg');
      background-size: 50% 75%;
    }
  }
}
</style>
